<template>
    <div id="product" class="product">
        <Header />
        <div class="content container">
            <div class="query">
                <span :class="activeQuery === 'new' ? 'active-query' : ''" @click="onSelectQueryType('new')">
                    最新课程
                </span>
                <span class="br"></span>
                <span :class="activeQuery === 'most' ? 'active-query' : ''" @click="onSelectQueryType('most')">
                    最多购买
                </span>
                <span class="br"></span>
                <div
                    class="price"
                    @click="onPriceSort"
                    :class="['top', 'bottom'].includes(activeQuery) ? 'active-query' : ''"
                >
                    <span>价格排序</span>
                    <img v-show="activeQuery === 'top'" class="bottom" src="https://res.peki.vip/20230317/8086de9896be4f69a8f13f19cd3ccfe8.png" />
                    <img v-show="activeQuery !== 'top'" class="top" src="https://res.peki.vip/20230317/38edb0b443734c2cab082d85f2761d2a.png" />
                    <img v-show="activeQuery !== 'bottom'" class="bottom" src="https://res.peki.vip/20230317/38edb0b443734c2cab082d85f2761d2a.png" />
                    <img v-show="activeQuery === 'bottom'" class="top" src="https://res.peki.vip/20230317/8086de9896be4f69a8f13f19cd3ccfe8.png" />
                </div>
            </div>
            <div class="product-list">
                <div
                    class="product-item"
                    v-for="item in productList"
                    :key="item.id"
                    @click="toProductDetailsPage(item)"
                >
                    <img class="cover" v-lazy="item.bannerImageUrl" />
                    <div class="title ov-ellipsis">{{ item.title }}</div>
                    <div class="desc">
                        {{ item.sellExtra.intro }}
                    </div>
                    <div class="other">
                        <div class="lesson-cnt">{{ item.sellExtra.lessonCnt || 0 }}课时</div>
                    </div>
                </div>
            </div>
        </div>
        <Fotter />
    </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
import { getRecommend } from '@/api/courseApi.js'

export default {
    name: 'product',
    components: { Header, Fotter },
    data() {
        return {
            originalProductList: [],
            productList: [],
            activeQuery: '',
        }
    },
    created() {
        this.getRecommendApi()
    },
    methods: {
        onSelectQueryType(query) {
            if (this.activeQuery && this.activeQuery === query) {
                this.activeQuery = ''
                this.productList = [...this.originalProductList]
            } else if (query === 'new') {
                this.activeQuery = query
                this.productList.sort((a, b) => {
                    return a.addTime < b.addTime ? 1 : -1
                })
            } else if (query === 'most') {
                this.activeQuery = query
                this.productList.sort((a, b) => {
                    return a.buyCnt < b.buyCnt ? 1 : -1
                })
            }
        },
        onPriceSort() {
            if (this.activeQuery === '' || !['top', 'bottom'].includes(this.activeQuery)) {
                this.activeQuery = 'top'
                this.productList.sort((a, b) => {
                    return a.price < b.price ? 1 : -1
                })
            } else if (this.activeQuery === 'top') {
                this.activeQuery = 'bottom'
                this.productList.sort((a, b) => {
                    return a.price > b.price ? 1 : -1
                })
            } else {
                this.activeQuery = ''
                this.productList = [...this.originalProductList]
            }
        },
        toProductDetailsPage(item) {
            window.open(`/productDetails/${item.packageNo}`, '_blank')
        },
        getRecommendApi() {
            getRecommend({ offset: 0, limit: 30 }).then((res) => {
                this.productList = res.data
                this.originalProductList = res.data
            })
        },
    },
}
</script>

<style lang="scss" scoped>
#product {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
.product {
    background: $theme-bg-color;
    .container {
        width: $center-width;
        padding: 24px 0;
        margin: 0 auto;
    }
    .query {
        padding: 0 0 24px 20px;
        display: flex;
        font-size: 14px;
        color: #33312f;
        line-height: 14px;
        user-select: none;
        span {
            cursor: pointer;
        }
        .price {
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
                margin-right: 2px;
            }
            img {
                width: 3px;
                height: 12px;
                background: none;
                margin-left: 2px;
            }
            .top {
                transform: rotate(180deg);
            }
        }
        .active-query {
            font-weight: 700;
            color: $theme-color;
        }
        .br {
            width: 1px;
            height: 14px;
            background: #99948d;
            margin: 0 16px;
        }
    }
    .product-list {
        display: flex;
        flex-wrap: wrap;
        .product-item {
            margin: 0 9px 24px;
            width: 300px;
            height: 320px;
            background: #ffffff;
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
            &:hover {
                transition: all 0.2s;
                transform: translateY(-8px);
                box-shadow: 0px 8px 12px 0px rgba(51, 49, 47, 0.12);
            }
            .cover {
                width: 300px;
                height: 174px;
                display: block;
            }
            .title {
                font-size: 16px;
                color: #33312f;
                line-height: 16px;
                margin: 20px 0 12px;
                font-weight: 700;
                width: 96%;
                &::before {
                    content: '';
                    width: 4px;
                    height: 16px;
                    background: $theme-color;
                    display: inline-block;
                    margin-right: 12px;
                    vertical-align: -2px;
                }
            }
            .desc {
                height: 54px;
                font-size: 12px;
                color: #66625e;
                line-height: 1.6em;
                min-height: 24px;
                margin: 0 16px;
                @include ov-ellipsiss(3);
            }
            .other {
                margin: 10px 16px 0;
                display: flex;
                justify-content: space-between;
                .lesson-cnt {
                    font-weight: 700;
                    font-size: 16px;
                    color: $theme-color;
                    line-height: 22px;
                }
            }
        }
    }
}

@media screen and (max-width: 980px) and (min-width: 640px) {
    .product {
        background: #f7f7f7;
        .container {
            width: 100%;
            padding: 20px;
        }
        .product-list {
            display: flex;
            flex-wrap: wrap;
            .product-item {
                margin: 0 20px 28px;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .product {
        background: #f7f7f7;
        .container {
            width: auto;
            padding: 20px 0;
        }
        .product-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 18px;
            .product-item {
                max-width: 340px;
                width: 100%;
                height: auto;
                background: #ffffff;
                .cover {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
</style>
